/* Valvoline-style */
* {
  margin: 0;
  padding: 0;
  font-size: 13px;
}

.side-menu a {
  display: block;
  padding: 1.2rem 0rem;
}

.side-menu a.active {
  background: #035bb0;
}

.side-menu a.active span,
.side-menu a.active small {
  color: #fff;
}

.side-menu a span {
  display: block;
  text-align: left;
  font-size: 1.7rem;
}

.side-menu a span,
.side-menu a small {
  color: #899dc1;
}

#menu-toggle:checked ~ .sidebar {
  width: 60px;
}

#menu-toggle:checked ~ .sidebar .side-header span {
  display: none;
}

#menu-toggle:checked ~ .main-content {
  margin-left: 60px;
  width: calc(100% - 60px);
}

#menu-toggle:checked ~ .main-content header {
  left: 60px;
}

#menu-toggle:checked ~ .sidebar .profile,
#menu-toggle:checked ~ .sidebar .side-menu a small {
  display: none;
}

#menu-toggle:checked ~ .sidebar .side-menu a span {
  font-size: 1.3rem;
}
.p-dialog .p-dialog-content {
  /* background: #fe8c00; */
  background-color: rgb(235, 238, 242) !important;

  color: #ffffff;
  padding: 0 1.5rem 2rem 1.5rem;
}
.p-dialog .p-dialog-content {
  color: #495057;
  padding: 2rem !important;
}
.reset_content {
  width: 32%;
}
.icon1 input {
  border: none;
}
.p-input-icon-left > i:first-of-type {
  left: 0.5rem !important;
  color: #6c757d;
}

.boxx .cards {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important;
}
.m-top {
  margin-top: 1rem;
}
.m-top1 {
  margin-top: rem;
}
/* responsiveness */
@media (max-width: 768px) {
  .reset_content {
    width: 90%;
  }
  .m-top {
    margin-top: 1rem;
  }
  .m-top1 {
    margin-top: 1rem;
  }
  .side-content {
    height: calc(14vh - 60px);
    overflow: auto;
  }
}

.page-content {
  /* padding: 0.5rem; */
  background: #f8f8f9;
}

.buttonShowMap {
  background: #ffffff;
  padding: 15px 0 0 0;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important;
}

.analytics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  /* margin-top: 0.3rem;
  margin-bottom: 1.5rem; */
  background: #ffffff;
  padding: 15px 0;
  /* box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important; */
}
.card {
  box-shadow: 0px 5px 5px -5px rgb(0 0 0 / 10%) !important;
  background: #fff;
  padding: 1rem;
  border-radius: 3px;
  border: 1px solid #f1eeee !important;
}

.card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-head h2 {
  color: #333;
  font-size: 1.9rem;
  font-weight: 500;
}

.card-head span {
  font-size: 2.5rem;
  color: #777;
  margin: 0px !important;
}

.issue {
  color: #22baa0 !important;
}

.map {
  color: #11a8c3 !important;
}

.demap {
  color: #f6d433 !important;
}

.damage {
  color: #f25656 !important;
}

.card-progress small {
  color: #777;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 2px;
}

.card-indicator {
  margin: 0.7rem 0rem;
  height: 10px;
  border-radius: 4px;
  background: #e9edf2;
  overflow: hidden;
}

.indicator {
  height: 10px;
  border-radius: 4px;
}

.indicator.one {
  background: #22baa0;
}

.indicator.two {
  background: #11a8c3;
}

.indicator.three {
  background: #f6d433;
}

.indicator.four {
  background: #f25656;
}

@media only screen and (max-width: 1200px) {
  .analytics {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .analytics {
    grid-template-columns: 100%;
  }
  .main-content {
    margin-left: 0;
    width: 100%;
  }
  main {
    background: #f3f4f6;
    grid-area: main;
    overflow-y: auto;
    margin-top: -1rem;
  }
  i.pi.pi-bars {
    font-size: 20px;
  }
}
