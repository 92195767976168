/*-- LOGIN-style --*/
/*-- reset --*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
nav ul,
nav li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*-- start editing from here --*/
a {
  text-decoration: none;
}
.txt-rt {
  text-align: right;
} /* text align right */
.txt-lt {
  text-align: left;
} /* text align left */
.txt-center {
  text-align: center;
} /* text align center */
.float-rt {
  float: right;
} /* float right */
.float-lt {
  float: left;
} /* float left */
.clear {
  clear: both;
} /* clear float */
.pos-relative {
  position: relative;
} /* Position Relative */
.pos-absolute {
  position: absolute;
} /* Position Absolute */
.vertical-base {
  vertical-align: baseline;
} /* vertical align baseline */
.vertical-top {
  vertical-align: top;
} /* vertical align top */
nav.vertical ul li {
  display: block;
} /* vertical menu */
nav.horizontal ul li {
  display: inline-block;
} /* horizontal menu */
img {
  max-width: 100%;
}
/*-- end reset --*/
/* body {
  font-family: "Hind", sans-serif;
} */
/*-- main --*/

/*-- slide --*/

@keyframes slideleft {
  from {
    background-position: 0%;
  }
  to {
    background-position: 90000%;
  }
}

@-webkit-keyframes slideleft {
  from {
    background-position: 0%;
  }
  to {
    background-position: 90000%;
  }
}
.logo {
  height: 120px;
  margin: 0 auto;
  max-width: 700px;
  background: url("../assets/images/LoginLogo.png") no-repeat center center;
  background-size: contain;
}
.w3layouts-main {
  background: url("../assets/images/login-bg.png");
  background-repeat: repeat-x;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-attachment: fixed;
  position: relative;
  min-height: 100vh;
}

.bg-layer {
  /* background: rgb(0 0 0 / 60%); */
  min-height: 100vh;
}
/*-- //slide --*/

/*--header start here--*/
.w3ls-header {
  padding: 0em 0 0;
}
.icon1 {
  margin: 0 0 1em;
  padding: 0.2em 1em;
  background: rgba(255, 255, 255, 0.94);
}

.icon1 span.fa {
  color: #222;
  width: 22px;
}
.main-icon {
  text-align: center;
}
.main-icon span.fa {
  font-size: 50px;
  color: #fff;
  margin-bottom: 1em;
}
.wthree li {
  display: inline-block;
}
a {
  color: #b4e3fd;
  margin: 0em;
}
.bottom {
  margin: 1em 0 0;
}
.header-main {
  max-width: 400px;
  margin-left: 2%;
  margin-right: auto;
  position: relative;
  margin-top: 5%;
  bottom: auto;
  z-index: 999;
  padding: 1rem 2em 2em 2em;
  background-color: #ffffff;
  /* box-shadow: -1px 4px 28px 0px rgb(0 0 0 / 10%); */
}
.labeldata label {
  margin-bottom: 10px;
  color: #fff;
}
.reset_pop label {
  color: #000;
}
.text_color {
  color: #fff;
  cursor: pointer;
}
.forgot {
  text-align: right;
}
button,
input {
  overflow: visible;
}
.popup-modal {
  width: 30%;
}

.reset_pop input {
  overflow: visible;
  border: 1px solid !important;
}
input[type="checkbox" i] {
  display: inline-block !important;
}
.sign-up {
  margin: 2em 0;
}
.header-left {
  background: #fff;
  padding: 0px;
}
.sign-up h2 {
  font-size: 22px;
  color: #fff;
  text-align: center;
  background: #fbbc05;
  width: 40px;
  height: 40px;
  line-height: 1.9em;
  border-radius: 50%;
  margin: 0 auto;
}
::-webkit-input-placeholder {
  color: #333 !important;
}
.header-left-bottom input[type="email"] {
  outline: none;
  font-size: 15px;
  color: #222;
  border: none;
  display: inline-block;
  background: transparent;
  letter-spacing: 1px;
}
.header-left-bottom input[type="password"] {
  outline: none;
  font-size: 15px;
  color: #222;
  /* border:none; */
  width: 1%;
  display: inline-block;

  letter-spacing: 1px;
}
.header-left-bottom button.btn {
  /* background: #007cc0; */
  background: linear-gradient(90deg, #0072ff 0%, #00d4ff 100%);
  border: double #fff;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  padding: 0.8em 2em;
  letter-spacing: 1px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  display: inline-block;
  cursor: pointer;
  outline: none;
  /* border: none; */
  width: 100%;
}
.header-left-bottom button.btn:hover {
  background: linear-gradient(90deg, #00d4ff 0%, #0072ff 100%);
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 30%);
}

/*-- agileits --*/
.header-left-bottom p {
  font-size: 17px;
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 20px 0 0;
  letter-spacing: 1px;
  float: left;
}

.header-left-bottom p.right {
  text-align: right;
}
.header-left-bottom p a {
  font-size: 11px;
  color: #e2e2e2;
  text-transform: uppercase;
}
.login-check {
  position: relative;
}

input[type="checkbox" i] {
  display: none;
}

h1 {
  font-size: 30px;
  color: #414141;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: center;
  padding: 1em 0 0em 0;
}
.btn-primary {
  margin: 0 0px !important;
}
.forgot-pass {
  color: #000;
  cursor: pointer;
}
/*-- responsive-design --*/

@media (max-width: 667px) {
  .header-main {
    max-width: 500px;
    margin: 40px 20px;
  }
  h1 {
    font-size: 28px;
    letter-spacing: 2px;
    line-height: 40px;
  }
}

@media (max-width: 415px) {
  h1 {
    font-size: 35px;
    letter-spacing: 3px;
  }
  .social {
    margin: 1em 0 0;
  }
  .copyright {
    padding: 2em 1em;
  }
}
@media (max-width: 384px) {
  h1 {
    font-size: 22px;
    margin-bottom: 15px;
    line-height: 1px;
  }
  .header-mid h1 {
    font-size: 15px;
    padding: 0px;
    color: #0063b1;
    letter-spacing: 1px;
    text-align: end;
  }
}
/*--end responsive-design --*/
